import {
    apiFailureErrorMessage,
    HttpService,
} from '../utils/httpService/HttpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class CodingQuestionService {
    constructor(private httpService: HttpService) {}

    async getCodingQuestionsForModule(moduleId: string) {
        const url = `${ENDPOINTS.ROOT}/user-course-enrollment/${moduleId}/codingQuestions`;
        try {
            const { data } = await this.httpService.get<any>(url);
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async fetchQuestion(body: any) {
        const url = `${ENDPOINTS.ROOT}/coding-question/question`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: body,
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async runCustomInput(input: any) {
        const reqInput = { ...input };
        const url = `${ENDPOINTS.ROOT}/coding-question/runTestCase`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: reqInput,
            });
            console.log('run test case', data);
            return data[0];
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async getSolution(questionId: any) {
        const url = `${ENDPOINTS.ROOT}/coding-question/getSolution/${questionId}`;
        try {
            const { data } = await this.httpService.post<any>(url);
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async getExpectedOutput(input: any) {
        const reqInput = { ...input };
        const url = `${ENDPOINTS.ROOT}/coding-question/getExpectedOutput`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: reqInput,
            });
            return data[0];
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async submitCode(input: any) {
        const url = `${ENDPOINTS.ROOT}/judge0/submission`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: input,
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async getAptitudeQuestionById(body: any) {
        const url = `${ENDPOINTS.ROOT}/contests/conceptual-question`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: body,
            });
            console.log('aptitude res', data);
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async submitAptitudeByModuleId(moduleData: any) {
        const url = `${ENDPOINTS.ROOT}/conceptual-question/submit`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: moduleData,
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async saveAptitudeQuestions(questionData: any) {
        const url = `${ENDPOINTS.ROOT}/contests/conceptual/save`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: questionData,
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }
}
