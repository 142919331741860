import Cookies from 'js-cookie';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const IDEScreen = () => {
    const location = useLocation();
    const ContestState = useSelector((state: any) => state.contest);

    const { contestId } = ContestState;

    const questionId: Array<string | null> | null | string = queryString.parse(
        location.search
    ).question;

    const poolId: Array<string | null> | null | string = queryString.parse(
        location.search
    ).pool;

    const endpoint = encodeURIComponent('https://acciojob-backend-eobnd7jx2q-el.a.run.app');
    const platform = 'restricted';
    const type = 'contest';
    const source = encodeURIComponent(window.location.origin);
    const hostingPlatform = 'https://ide.acciojob.com';
    const token = Cookies.get('acciojobs-token');

    return (
        <iframe
            className={'CodeEditor'}
            src={`${hostingPlatform}/?question=${questionId}&endpoint=${endpoint}&platform=${platform}&type=${type}&contest=${contestId}&pool=${poolId}&source=${source}#accessToken=${token}`}
        />
    );
};

export default IDEScreen;
