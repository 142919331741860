import moment from 'moment';

export class ContestEngagement {
    contestStarted(
        contestName: string,
        contestId: string,
        isCodingContest: boolean
    ) {
        try {
            // @ts-ignore
            window.webengage.track('contest_started', {
                contest_id: contestId,
                contest_name: contestName,
                // start_time: new Date(),
                start_time: moment().toDate(),
                is_coding_test: isCodingContest,
            });
            console.log(contestName, contestId, isCodingContest);
        } catch (e) {
            console.log(e);
        }
    }

    contestEnded(
        contestName: string,
        contestId: string,
        isCodingContest: boolean,
        score: string,
        testCleared: boolean
    ) {
        try {
            // @ts-ignore
            window.webengage.track('contest_submitted', {
                contest_id: contestId,
                contest_name: contestName,
                // submission_time: new Date(),
                submission_time: moment().toDate(),
                is_coding_test: isCodingContest,
                coding_test_cleared: testCleared,
                score_percent: score,
            });
            console.log(contestName, contestId, isCodingContest, score);
        } catch (e) {
            console.log(e);
        }
    }
}
