interface ButtonProps {
	title: string;
	action?: Function;
	customClass?: string;
	className?: string;
	variant?: 'primary' | 'solved' | 'outline' | 'disable';
	icon?: any;
	iconPosition?: 'left' | 'right';
	disabled?: boolean;
	btnType?: 'button' | 'submit' | 'reset';
}

const AccioButton = ({
	title,
	action,
	customClass,
	className,
	variant,
	icon,
	disabled,
	btnType,
	iconPosition,
}: ButtonProps) => {
	return (
		<button
			type={btnType}
			className={`elm-btn btn-${variant} ${
				className ? className : ''
			} ${customClass ? customClass : ''}`}
			onClick={() =>
				action && variant !== 'disable' ? action() : null
			}
			disabled={disabled}
			style={{
				display: 'flex',
				flexDirection: `${
					iconPosition === 'left' ? 'row-reverse' : 'row'
				}`,
				justifyContent: 'center',
			}}
		>
			{title}
			{icon ? (
				<span className="btn-icon">{icon}</span>
			) : null}
		</button>
	);
};

export default AccioButton;
