import React, { useState } from 'react';
// @ts-ignore
import { useTimer } from 'react-timer-hook';
// @ts-ignore
import $ from 'jquery';

interface TimerProps {
    expiryTimestamp: any;
    show: any[];
    expiryHandler: any;
}

const Timer = ({ expiryTimestamp, show, expiryHandler }: TimerProps) => {
    // console.log(props);
    // const [days, setDays] = useState(0);
    // const [hours, setHours] = useState(0);
    // const [minutes, setMinutes] = useState(0);
    // const [seconds, setSeconds] = useState(0);
    const [active, setActive] = useState(true);
    const [activePercentage, setActivePercentage] = useState(0);

    // const timeleft = new Date();
    // timeleft.setSeconds(timeleft.getSeconds() + 100000);
    // const expiryTimestamp = timeleft;

    const { seconds, minutes, hours, days } = useTimer({
        expiryTimestamp,
        onExpire: () => {
            console.warn('onExpire called');
            expiryHandler();
        },
    });

    return (
        <div className={'timer'}>
            <div id="countdown">
                <ul>
                    {show[0] ? (
                        <li>
                            <span id="days">{days}</span>DAY
                        </li>
                    ) : null}
                    {show[1] ? (
                        <li>
                            <span id="hours">{hours}</span>HRS
                        </li>
                    ) : null}
                    {show[2] ? (
                        <li>
                            <span id="minutes">{minutes}</span>MIN
                        </li>
                    ) : null}
                    {show[3] ? (
                        <li>
                            <span id="seconds">{seconds}</span>SEC
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    );
};

export default Timer;
