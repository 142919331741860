import React, { useEffect } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import 'react-reflex/styles.css';

import { FaGithub } from 'react-icons/fa';
import GithubAuth from './GithubAuth';

// import MainEditor from './Codemirror/Editor';
import QuestionPanel from './QuestionPanel';
import { Button } from '@mui/material';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import Loader from '../../components/Loader/Loader';
// import LocalLoader from '../../components/Loader/LocalLoader';
// import { fetchCodingQuestions } from '../../redux/precourse/precourseSlice';
// import { notifyInfo, notifySuccess } from '../../components/Notification/index';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import MonacoEditor from './Monaco/Monaco';
import {
    fetchWebDevQuestionData,
    startAssignment,
} from '../../redux/webDevQuestion/webDevQuestionSlice';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// interface RouterProps {
// 	match: any;
// }

const WebDevScreen = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const questionId: string = queryString.parse(location.search).question;
    const editorState = useSelector((state: any) => state.webDevQuestion);
    // const code: string = queryString.parse(location.search).code;
    // const { codingQuestiondata } = useSelector((state: any) => state.precourse);
    const {
        loading,
        isThemeDark,
        questionData,
        error,
        startingAssignment,
        acceptedInvitation,
        solutionRepo,
    } = editorState;

    const searchParams = new URLSearchParams(location.search);

    console.log('searchParams', searchParams.get('code'));

    useEffect(() => {
        if (questionId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(fetchWebDevQuestionData(questionId));
            localStorage.setItem('questionId', questionId);
        }
    }, [questionId]);

    useEffect(() => {
        if (searchParams.get('code')) {
            console.log('CODE ==== ', searchParams.get('code'));
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                startAssignment({
                    questionId: questionId,
                    code: searchParams.get('code') || undefined,
                })
            );
        }
    }, []);

    const onClickStartSolving = async () => {
        dispatch(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            startAssignment({
                questionId: questionId,
                code: searchParams.get('code') || undefined,
            })
        );
    };

    return (
        <div className={'web-idle'}>
            <ReflexContainer
                orientation="vertical"
                className={'reflex-container-top'}
            >
                <ReflexElement className="left-pane">
                    <div style={{ height: '100%', overflow: 'auto' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            ></Box>
                            {questionData !== null ? (
                                <QuestionPanel questionData={questionData} />
                            ) : null}
                        </Box>
                    </div>
                </ReflexElement>

                <ReflexSplitter style={{ width: '10px' }} />

                <ReflexElement className="right-pane">
                    {solutionRepo ? (
                        <div className={'git-panel'}>
                            <FaGithub />
                            <h2>The name of your solution Github Repo</h2>
                            <a
                                href={`https://github.com/acciojob/${solutionRepo}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {solutionRepo}
                            </a>
                            {questionData.scoreReceived !== -1 && (
                                <div style={{ fontSize: 18 }}>
                                    Your Score: {questionData.scoreReceived}/
                                    {questionData.score}
                                </div>
                            )}
                            {!acceptedInvitation && (
                                <div style={{ fontSize: 19, color: 'red' }}>
                                    We encountered some problem regarding
                                    automatically accepting the assignment.
                                    However, an invitation link is sent to your
                                    github registered email. Please accept the
                                    assignment by clicking on the invitation
                                    link.
                                </div>
                            )}
                        </div>
                    ) : (
                        <Button
                            onClick={onClickStartSolving}
                            className={'startbtn'}
                        >
                            {startingAssignment ? (
                                <>
                                    {/*<CircularProgress />*/}
                                    Starting
                                </>
                            ) : (
                                <>Start Solving</>
                            )}
                        </Button>
                    )}
                    {error.state ? (
                        error.message === 'GITHUB_AUTH_NEEDED' &&
                        error.githubAuthLink ? (
                            <GithubAuth githubAuthUri={error.githubAuthLink} />
                        ) : (
                            <div>Error: {error.message}</div>
                        )
                    ) : null}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};

export default WebDevScreen;
