import React, { useEffect, useState } from 'react';
import Timer from '../timer';

import { useDispatch, useSelector } from 'react-redux';
import { showEndModal } from '../../redux/contest/contestSlice';
import Webcam from 'react-webcam';
import moment from 'moment';

interface Props {
    endContestHandler: any;
}

const ContestNavigation = ({ endContestHandler }: Props) => {
    const dispatch = useDispatch();

    const ContestState = useSelector((state: any) => state.contest);

    const {
        contestData,
        questionData,
        totalQuestion,
        solvedQuestion,
        contestStarted,
        timeElapsed,
        contestId,
        contestEnded,
        showModal,
        forcefullyStart,
        isWebCamAllowed
    } = ContestState;

    const [expired, setExpired] = useState(false);
    const [showTimer, setShowTimer] = useState(false);

    // const [timeLeft, setTimeLeft] = useState(new Date());
    const [timeLeft, setTimeLeft] = useState(moment().toDate());

    const expiryHandler = () => {
        setExpired(true);
        if (forcefullyStart) {
            let event = new Event('apStopMonitoring');
            window.dispatchEvent(event);
            document.exitFullscreen();
        } else {
            let event = new Event('endContestEvent');
            window.dispatchEvent(event);
        }
        dispatch(showEndModal(true));
        localStorage.removeItem('isWebCamAllowed');
    };

    useEffect(() => {
        if (questionData !== null) {
            let timeleft = moment().toDate();
            timeleft.setSeconds(
                timeleft.getSeconds() +
                    Number(contestData.duration) * 60 -
                    timeElapsed
            );
            setTimeLeft(timeleft);
            setShowTimer(true);
            console.log('TIMER FOR TEST', timeleft);
        }
    }, [questionData]);

    return (
        <div className={'contest-navigation'}>
            <div className={'banner-name'}>
                <h3>{contestData != null ? contestData.name : null}</h3>
            </div>

            {contestStarted && !contestEnded ? (
                <div className={'navigation-bar'}>
                    {isWebCamAllowed ?<Webcam className={'webcam'} />: null}
                    {/*<div className={'progress'}>*/}
                    {/*    <p>*/}
                    {/*        {solvedQuestion}/{totalQuestion} completed*/}
                    {/*    </p>*/}
                    {/*    <p className={'progress-bar'}>*/}
                    {/*        <span*/}
                    {/*            className={'filled'}*/}
                    {/*            style={{*/}
                    {/*                width: `${*/}
                    {/*                    (solvedQuestion / totalQuestion) * 100*/}
                    {/*                }%`,*/}
                    {/*            }}*/}
                    {/*        ></span>*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className={'window-timer'}>
                        <p>Remaining Time</p>
                        {showTimer && !showModal ? (
                            <Timer
                                expiryTimestamp={timeLeft}
                                show={[0, 1, 1, 1]}
                                expiryHandler={expiryHandler}
                            />
                        ) : null}
                    </div>
                    <button
                        // id="testEnd"
                        onClick={endContestHandler}
                        className={'end-contest-btn'}
                    >
                        Submit Contest
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default ContestNavigation;
