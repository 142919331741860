import React, { useEffect, useState } from 'react';

import RenderMarkdown from './DisplayAsMarkDown';

import '../../../styles/aptitude.css';

interface Props {
    statement: string;
    options: string[];
    save: any;
    response: string[];
}

const IntegerType = (props: Props) => {
    console.log('props', props);

    const [inputValue, setInputValue] = useState('');

    const saveAnswer = () => {
        const temp = [inputValue];
        console.log('Int temp', temp);
        props.save(temp);
    };

    useEffect(() => {
        console.log('props response', props.response[0]);
        setInputValue(props.response[0]);
    }, [props.response]);

    return (
        <div className={'integer-type conceptual-question'}>
            <div className={'question'}>{RenderMarkdown(props.statement)}</div>
            <div className={'answer'}>
                <input
                    type={'number'}
                    placeholder={'type your answer'}
                    value={inputValue}
                    onChange={(event) => setInputValue(event.target.value)}
                />
            </div>

            <button
                onClick={saveAnswer}
                className={'save-btn'}
            >
                Save
            </button>

            {/*{!props.submitted ? (*/}
            {/*	<button*/}
            {/*		onClick={saveAnswer}*/}
            {/*		className={'save-btn'}*/}
            {/*	>*/}
            {/*		Save*/}
            {/*	</button>*/}
            {/*) : null}*/}
            {/*{props.submitted ? (*/}
            {/*	<div className={'correct-answer'}>*/}
            {/*		<h1>Correct Answer</h1>*/}
            {/*		<ul>*/}
            {/*			{props.correct.map((el: any) => {*/}
            {/*				return <li key={el}>{el}</li>;*/}
            {/*			})}*/}
            {/*		</ul>*/}
            {/*	</div>*/}
            {/*) : null}*/}
        </div>
    );
};

export default IntegerType;
