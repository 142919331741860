import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkHTML from 'remark-html';
import React from 'react';

const RenderMarkdown = (children: any) => {
    return (
        <ReactMarkdown
            className={`markdown-body${
                window.location.pathname.includes('conceptual-question')
                    ? ' user-not-select-text'
                    : ''
            }`}
            rehypePlugins={[[rehypeHighlight], [rehypeRaw]]}
            remarkPlugins={[remarkGfm, remarkHTML]}
        >
            {children}
        </ReactMarkdown>
    );
};

export default RenderMarkdown;
