import React, { useEffect, useState } from 'react';
import RenderMarkdown from './DisplayAsMarkDown';

import '../../../styles/aptitude.css';

interface Props {
    statement: string;
    options: string[];
    save: any;
    response: string[];
}

const Subjective = (props: Props) => {

	const [inputValue, setInputValue] = useState('');

	const saveAnswer = () => {
		const temp = [inputValue];
		console.log('Int temp', temp);
		props.save(temp);
	};

	useEffect(() => {
		console.log('props response', props.response[0]);
		setInputValue(props.response[0]);
	}, [props.response]);

	return (
		<div className={'subjective conceptual-question'}>
			<div className={'question'}>{RenderMarkdown(props.statement)}</div>
			<div className={'answer'}>
				<textarea
					value={inputValue}
					onChange={(event) => setInputValue(event.target.value)}
					placeholder={'Type your answer'}
				/>
			</div>

			<button
				onClick={saveAnswer}
				className={'save-btn'}>
				Save
			</button>

			{/*{props.submitted ? (<div className={'correct-answer'}>*/}
			{/*	<h1>Correct Answer</h1>*/}
			{/*	<ul>*/}
			{/*		{props.correct.map((el: any) => {*/}
			{/*			return <li key={el}>{el}</li>;*/}
			{/*		})}*/}
			{/*	</ul>*/}
			{/*</div>) : null}*/}
		</div>
	);
};

export default Subjective;
