import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { services } from '../../api';

export const fetchModuleQuestions = createAsyncThunk(
    'assignments/fetchQuestions',
    async (moduleId: string, { getState }) => {
        // console.log(arg)
        const data =
            await services.courseService.getFullCourseQuestionsForModule(
                moduleId,
            );
        console.log('fetchQuestions', moduleId, data);
        return data;
    },
);

export const fetchAllModules = createAsyncThunk(
    'assignments/fetchAll',
    async (courseID) => {
        const data = await services.courseService.getCourseAssignments();
        console.log('fetchAllModules', data);
        data.courseID = courseID;
        return data;
    },
);

interface ModuleState {
    allModules: any;
    modulesData: any;
    allModulesData: any;
    switching: boolean;
    // extra
    moduleListloading: boolean;
    moduleloading: boolean;
    error: {
        state: boolean;
        message: string;
    };
}

const initialState: ModuleState = {
    allModules: {},
    modulesData: {},
    allModulesData: null,
    switching: false,
    // extra
    moduleListloading: true,
    moduleloading: true,
    error: {
        state: false,
        message: '',
    },
};

export const moduleSlice: any = createSlice({
    name: 'assignment',
    initialState,
    reducers: {
        handleSwitch: (state, action) => {
            state.moduleListloading = true;
            state.error.state = false;
            state.error.message = '';
            state.modulesData = {};
            state.allModulesData = null;
        },
        updateModules(state, action) {
            state.moduleListloading = false;
            state.allModulesData = state.allModules[action.payload];
            state.error.state = false;
            state.error.message = '';
        },
    },
    extraReducers: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchModuleQuestions.pending]: (
            state: ModuleState,
            action: PayloadAction<any>,
        ) => {
            state.moduleloading = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchModuleQuestions.fulfilled]: (
            state: ModuleState,
            action: PayloadAction<any>,
        ) => {
            state.moduleloading = false;
            state.modulesData[action.payload.module.id] = action.payload;
            state.error.state = false;
            state.error.message = '';
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchModuleQuestions.rejected]: (
            state: ModuleState,
            action: PayloadAction<any>,
        ) => {
            state.moduleloading = false;
            state.error.state = true;
            state.error.message = 'Error, please try again later';
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchAllModules.pending]: (
            state: ModuleState,
            action: PayloadAction<any>,
        ) => {
            state.moduleListloading = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchAllModules.fulfilled]: (
            state: ModuleState,
            action: PayloadAction<any>,
        ) => {
            console.log('Payload', action.payload);
            state.moduleListloading = false;
            state.allModulesData = action.payload;
            state.error.state = false;
            state.error.message = '';
            const temp = { ...action.payload };
            if (state.allModules[action.payload.courseID] === undefined) {
                state.allModules[action.payload.courseID] = temp;
            }
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchAllModules.rejected]: (
            state: ModuleState,
            action: PayloadAction<any>,
        ) => {
            state.moduleListloading = false;
            state.error.state = true;
            state.error.message = 'Error, please try again later';
        },
    },
});

//Exporting Actions
export const { handleSwitch, updateModules } = moduleSlice.actions;

export default moduleSlice.reducer;
