import React, { useEffect, useState } from 'react';
import '../../../styles/aptitude.css';
import RenderMarkdown from './DisplayAsMarkDown';

import { IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io';

interface Props {
    statement: string;
    options: string[];
    save: any;
    response: string[];
}

const MultiSelect = (props: Props) => {
    const [answer, setAnswer] = useState<string[]>([]);

    const saveAnswer = (option: string) => {
        if (!answer.includes(option)) {
            const temp = [...answer, option];
            console.log('mcq temp', temp);
            setAnswer(temp);
            props.save(temp);
        } else {
            const temp = answer.filter((el) => el !== option);
            setAnswer(temp);
            props.save(temp);
            console.log('mcq temp remove', temp);
        }
    };

    useEffect(() => {
        console.log('props response', props.response);
        setAnswer(props.response);
    }, [props.response]);

    return (
        <div className={'multi-select conceptual-question'}>
            <div className={'question'}>{RenderMarkdown(props.statement)}</div>
            <div className={'options'}>
                <ul>
                    {props.options.map((el, index) => {
                        return (
                            <li
                                key={index + el}
                                onClick={() => {
                                    saveAnswer(el);
                                }}
                            >
                                <span>
                                    {answer.includes(el) ? (
                                        <IoIosRadioButtonOn />
                                    ) : (
                                        <IoIosRadioButtonOff />
                                    )}
                                </span>
                                <div className={'mcq-option'}>
                                    {RenderMarkdown(el)}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default MultiSelect;
