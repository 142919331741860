import React from 'react';
import logo from '../../assets/accio-logo.svg';
import { useSelector } from 'react-redux';

//@ts-ignore
import Cookies from 'js-cookie';

const Navigation = () => {
    const User = useSelector((state: any) => state.user);
    const { username } = User;
    const logout = () => {
        localStorage.clear();
        Cookies.remove('acciojobs-token', {
            domain: `.acciojob.com`,
        });
        window.open('https://auth.acciojob.com', '_self');
        console.log('logout');
    };

    return (
        <div className={'navigation'}>
            <nav>
                <div className={'logo'}>
                    <img
                        src={logo}
                        alt={'acciojob logo'}
                    />
                </div>
                <div className={'navigation-options'}>
                    <div className={'user-info'}>
                        <h3>{username}</h3>
                        {/*<button onClick={logout}>Logout</button>*/}
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navigation;
