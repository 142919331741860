import React from 'react';
import errorLottie from '../assets/lottie/error-boundary.json';
import Lottie from 'react-lottie-player';

interface Props {
    error: any;
    resetErrorBoundary: any;
}

const ErrorFallBack = ({ error, resetErrorBoundary }: Props) => {
    return (
        <div className={'error-fallback'}>
            <div className={'error-card'}>
                <h1>OOPS ! Something Went Wrong</h1>
                <Lottie
                    loop
                    animationData={errorLottie}
                    play
                    // style={{ width: 150, height: 150 }}
                />
                <p>Error : {error.message}</p>
            </div>
            <button onClick={resetErrorBoundary}>Try Again</button>
        </div>
    );
};

export default ErrorFallBack;
