import React from 'react';

const PageNotFound = () => {
  return (
    <div className={'page-not-found'}>
      <h1>Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
