import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CourseTokenService, TokenService } from '../token';
import Cookies from 'js-cookie';

export interface HandleStatusCode {
    [key: number]: (error: AxiosError) => void;
}

export const apiFailureErrorMessage = 'Failure in API call';

export class HttpService {
    constructor(
        protected tokenService: TokenService,
        protected courseTokenService: CourseTokenService
    ) {}

    async get<T>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        console.log('GET', url, config);
        return this.request<T>({ method: 'GET', url, ...config });
    }

    async post<T>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.request<T>({ method: 'POST', url, ...config });
    }

    async put<T>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.request<T>({ method: 'PUT', url, ...config });
    }

    async patch<T>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.request<T>({ method: 'PATCH', url, ...config });
    }

    async delete<T>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.request<T>({ method: 'DELETE', url, ...config });
    }

    async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const token: string = await this.tokenService.getToken();
            const defaultRequestInfo: AxiosRequestConfig = {
                headers: {
                    // 'Content-Type': 'application/json;charset=UTF-8',
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                    'acciojobs-token': token,
                },
            };
            return axios.request<T>({
                ...defaultRequestInfo,
                ...config,
                headers: {
                    ...defaultRequestInfo.headers,
                    ...config.headers,
                },
            });
        } catch (e: any) {
            console.log('error from request', e);
            if (e.response.status === 401 || e.response.status === 403) {
                console.log('unauthorized !!');
                localStorage.clear();
                Cookies.remove('acciojobs-token', {
                    domain: `.acciojob.com`,
                });
                window.open(
                    'https://authentication.acciojob.com/login/phone',
                    '_self'
                );
            }
            return e.response;
        }
    }

    onHttpErrorStatus(
        error: AxiosError,
        handleStatusCode: HandleStatusCode
    ): void {
        if (error.response && error.response.status) {
            const statusCode = error.response.status;
            const handleStatusCodeFn = handleStatusCode[statusCode];

            if (typeof handleStatusCodeFn === 'function') {
                handleStatusCodeFn(error);
            }
        }
    }
}
