import { ENDPOINTS } from '../config/endpoints/stage';
import { apiFailureErrorMessage, HttpService } from '../utils/httpService';

export class UserService {
    constructor(private httpService: HttpService) {}

    async getReport() {
        const url = `${ENDPOINTS.ROOT}/user-report-card`;
        try {
            console.log('dome', url);
            const { data } = await this.httpService.get<any>(url);
            console.log('report', data);
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async getUserAuthStatus() {
        const url = `${ENDPOINTS.ROOT}/users/userAuthStatus`;
        try {
            const { data } = await this.httpService.get<any>(url);
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }
}
