import React from 'react';

import { ScaleLoader } from 'react-spinners';

const Loader = () => {
    return (
        <div className={'loader-animation-div'}>
            <ScaleLoader className={'loader-animation'} />
        </div>
    );

};

export default Loader;