import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navigation from './components/navigation/navigation';
import Buffer from './screens/buffer/buffer';
import Contest from './screens/contest/contest';
import Dashboard from './screens/dashboard/dashboard';
import PageNotFound from './screens/pagenotfound/pageNotFound';
import GithubAuthRedirect from './screens/webdevidle/GithubAuthRedirect';

// @ts-ignore
import queryString from 'query-string';
import { useNavigate } from 'react-router';

const AppRouters = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showNav, setShowNav] = useState(true);

    useEffect(() => {
        const curr = location.pathname.split('/')[1];

        if (curr === 'contest') {
            setShowNav(false);
            const contestID: Array<string | null> | null | string =
                queryString.parse(location.search).contestID;
            // if (contestID === undefined || contestID === '') {
            //     navigate('/');
            // }
        } else {
            setShowNav(true);
        }
        console.log('LOCATION', location);
    }, [location]);

    return (
        <div className={'app-routers'}>
            {showNav ? <Navigation /> : null}
            <Routes>
                <Route
                    path={'/'}
                    element={<Dashboard />}
                />
                <Route
                    path={'/instructions'}
                    element={<Buffer />}
                />
                <Route
                    path={'/contest/*'}
                    element={<Contest />}
                />
                <Route
                    path="/github/auth"
                    element={<GithubAuthRedirect />}
                />
                <Route
                    path={'*'}
                    element={<PageNotFound />}
                />
            </Routes>
        </div>
    );
};

export default AppRouters;
