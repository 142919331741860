import React, { useEffect } from 'react';

import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestions } from '../../redux/contest/contestSlice';

const Questions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const contestState = useSelector((state: any) => state.contest);
    const {
        codingQuestions,
        webDevQuestions,
        contestId,
        contestHash,
        conceptualQuestions,
        sqlQuestions
    } = contestState;

    useEffect(() => {
        const data = {
            contestId: contestId,
            contestHash: contestHash,
        };

        console.log('GETTING ALL QUESTIONS>>>', contestState);
        // @ts-ignore
        dispatch(fetchQuestions(data));
    }, []);





    return (
        <div className={'all-questions-page'}>
            <div className={'all-questions-list'}>
                {conceptualQuestions.length > 0 ? (
                    <div className={'problem-solving'}>
                        <div className={'heading-container'}>
                            <h1 className={'heading'}>
                                Conceptual Questions
                            </h1>
                            <h3>Total : {conceptualQuestions.length}</h3>
                        </div>

                        <ul className={'questions'}>
                            {conceptualQuestions.map(
                                (el: any, index: number) => (
                                    <li key={el.questionId}>
                                        <div className={'question-details'}>
                                            <h1>Questions :{index + 1}</h1>
                                            <p>
                                                Max Score : {el.maxMarks} Points
                                            </p>
                                        </div>
                                        <button

                                            className={`${
                                                el.isQuestionAttemptedByUser
                                                    ? 'solved-btn'
                                                    : 'unsolved-btn'
                                            }`}
                                            onClick={() => {
                                                navigate(
                                                    `/contest/solve/conceptual-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                                                );
                                            }}
                                        >
                                            {el.isQuestionAttemptedByUser ? (
                                                <p className='attempted-question'>Solved</p>
                                            ) : (
                                                <p>
                                                    Solve <FiArrowRight />
                                                </p>
                                            )}
                                        </button>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                ) : null}

                {codingQuestions.length > 0 ? (
                    <div className={'problem-solving'}>
                        <div className={'heading-container'}>
                            <h1 className={'heading'}>
                                Program Solving
                            </h1>
                            <h3>Total : {codingQuestions.length}</h3>
                        </div>

                        <ul className={'questions'}>
                            {codingQuestions.map((el: any) => (
                                <li key={el.questionId}>
                                    <div className={'question-details'}>
                                        <h1>{el.questionTitle}</h1>
                                        <p>Max Score : {el.maxMarks} Points</p>
                                    </div>
                                    <button
                                        className={`${
                                            el.isQuestionAttemptedByUser
                                                ? 'solved-btn'
                                                : 'unsolved-btn'
                                        }`}
                                        onClick={() => {
                                            navigate(
                                                `/contest/solve/coding-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                                            );
                                        }}
                                    >
                                        {el.isQuestionAttemptedByUser ? (
                                            <p>Solved</p>
                                        ) : (
                                            <p>
                                                Solve <FiArrowRight />
                                            </p>
                                        )}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}

                {sqlQuestions.length > 0 ? (
                    <div className={'problem-solving'}>
                        <div className={'heading-container'}>
                            <h1 className={'heading'}>
                                SQL Questions
                            </h1>
                            <h3>Total : {sqlQuestions.length}</h3>
                        </div>

                        <ul className={'questions'}>
                            {sqlQuestions.map((el: any) => (
                                <li key={el.questionId}>
                                    <div className={'question-details'}>
                                        <h1>{el.questionTitle}</h1>
                                        <p>Max Score : {el.maxMarks} Points</p>
                                    </div>
                                    <button
                                        className={`${
                                            el.isSolved
                                                ? 'solved-btn'
                                                : 'unsolved-btn'
                                        }`}
                                        onClick={() => {
                                            navigate(
                                                `/contest/solve/sql-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                                            );
                                        }}
                                    >
                                        {el.isSolved ? (
                                            <p>Solved</p>
                                        ) : (
                                            <p>
                                                Solve <FiArrowRight />
                                            </p>
                                        )}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}

                {webDevQuestions.length > 0 ? (
                    <div className={'problem-solving'}>
                        <div className={'heading-container'}>
                            <h1 className={'heading'}>
                                Web Development
                            </h1>
                            <h3>Total : {webDevQuestions.length}</h3>
                        </div>

                        <ul className={'questions'}>
                            {webDevQuestions.map((el: any) => (
                                <li key={el.questionId}>
                                    <div className={'question-details'}>
                                        <h1>{el.questionTitle}</h1>
                                        <p>Max Score : {el.maxMarks} Points</p>
                                    </div>
                                    <button
                                        className={`${
                                            el.isQuestionAttemptedByUser
                                                ? 'solved-btn'
                                                : 'unsolved-btn'
                                        }`}
                                        onClick={() => {
                                            navigate(
                                                `/contest/solve/web-dev-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                                            );
                                        }}
                                    >
                                        {el.isQuestionAttemptedByUser ? (
                                            <p>Solved</p>
                                        ) : (
                                            <p>
                                                Solve <FiArrowRight />
                                            </p>
                                        )}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Questions;
