import { makeServices } from '../utils';
import { ContestEngagement } from './ContestEngagement';

const contestEngagement = new ContestEngagement();

export interface Engagements {
    contestEngagement: ContestEngagement;
}

export const { ServicesProvider, useServices } = makeServices<Engagements>({
    contestEngagement,
});

export const engagements = {
    contestEngagement,
};
