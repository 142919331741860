import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import codinglottie from '../../assets/lottie/coding.json';
import Timer from '../../components/timer';
import moment from 'moment';
import Loader from '../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchContests,
    resetContestData,
    setContestId,
    setContestSelected,
} from '../../redux/contest/contestSlice';

import { BiTimeFive } from 'react-icons/bi';

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [expired, setExpired] = useState(false);

    const [showList, setShowList] = useState(true);

    const expiryHandler = () => {
        setExpired(true);
    };

    useEffect(() => {
        localStorage.removeItem('auto-proctoring');
    }, []);

    const [timeLeft, setTimeLeft] = useState(null);
    const [showTimer, setShowTimer] = useState(false);

    const [contestNotStarted, setContestNotStarted] = useState(true);

    // const obj = { expiryTimestamp: timeleft };

    const ContestState = useSelector((state: any) => state.contest);
    const User = useSelector((state: any) => state.user);
    const { username, userRole } = User;

    const {
        contestData,
        loading,
        isAttempted,
        isContestLive,
        contestId,
        contestSelected,
        allContest,
        eligibility,
        onGoingContest,
    } = ContestState;

    useEffect(() => {
        if (contestData !== null) {
            let timeleft = new Date(contestData.endTime);
            // timeleft.setFullYear(timeleft.getFullYear() + 1);
            console.log('IN SECONDS', timeleft);
            // timeleft.setSeconds(timeleft.getSeconds());
            //@ts-ignore
            setTimeLeft(timeleft);
            setShowTimer(true);
            console.log('TIME LEFT', timeleft);

            console.log(
                moment(contestData.startTime).format('MMMM Do YYYY, h:mm:ss a'),
                moment().format('MMMM Do YYYY, h:mm:ss a'),
                moment(contestData.endTime).format('MMMM Do YYYY, h:mm:ss a'),
                moment().format('MMMM Do YYYY, h:mm:ss a')
            );

            console.log(
                moment(contestData.startTime).format(
                    'MMMM Do YYYY, h:mm:ss a'
                ) > moment().format('MMMM Do YYYY, h:mm:ss a') &&
                    moment(contestData.endTime).format(
                        'MMMM Do YYYY, h:mm:ss a'
                    ) > moment().format('MMMM Do YYYY, h:mm:ss a')
            );

            setContestNotStarted(
                moment(contestData.startTime) > moment() &&
                    moment(contestData.endTime) > moment()
            );
        }
    }, [contestData, contestId, contestSelected]);

    const selectContest = (contestID: string) => {
        dispatch(setContestId(contestID));
        // @ts-ignore
        dispatch(fetchContests(contestID));
        dispatch(setContestSelected(true));
    };

    const deSelectContest = () => {
        dispatch(setContestId(null));
        dispatch(setContestSelected(false));
        dispatch(resetContestData());
        setShowTimer(false);
    };

    useEffect(() => {
        console.log('contestData>>>', contestData);
        if (contestData) {
            if (contestData?.enableAutoProctor) {
                localStorage.setItem('auto-proctoring', 'enable');
            } else {
                localStorage.setItem('auto-proctoring', 'disable');
            }
        }
    }, [contestData]);

    return (
        <div className={'dashboard'}>
            <div className={'column-1'}>
                {/*<Loader />*/}
                <h1 className={'welcome'}>Welcome {username}</h1>
                {!contestSelected ? (
                    <div className={'all-contest-container'}>
                        {onGoingContest !== null ? (
                            <>
                                <h3 className={'title'}>Ongoing Contest</h3>
                                <ul className={'all-contest-list ongoing'}>
                                    {onGoingContest.map((el: any) => (
                                        <li
                                            key={el.contestId}
                                            className={'contest-item'}
                                        >
                                            <div
                                                className={'contest-item-name'}
                                            >
                                                <h3>{el.name}</h3>
                                            </div>
                                            <div
                                                className={
                                                    'contest-item-details'
                                                }
                                            >
                                                <h4>
                                                    <BiTimeFive />
                                                    {el.duration} min
                                                </h4>

                                                <button
                                                    onClick={() => {
                                                        selectContest(
                                                            el.contestId
                                                        );
                                                    }}
                                                >
                                                    Select
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : null}

                        <h3 className={'title'}>All Contests</h3>
                        <ul className={'all-contest-list'}>
                            {allContest.length > 0 ? (
                                allContest.map((el: any) => {
                                    return (
                                        <li
                                            key={el.contestId}
                                            className={'contest-item'}
                                        >
                                            <div
                                                className={'contest-item-name'}
                                            >
                                                <h3>{el.name}</h3>
                                            </div>
                                            <div
                                                className={
                                                    'contest-item-details'
                                                }
                                            >
                                                <h4>
                                                    <BiTimeFive />
                                                    {el.duration} min
                                                </h4>

                                                <button
                                                    onClick={() => {
                                                        selectContest(
                                                            el.contestId
                                                        );
                                                    }}
                                                    disabled={
                                                        el.blackListed === true
                                                    }
                                                    style={
                                                        el.blackListed === true
                                                            ? {
                                                                  background:
                                                                      'grey',
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    Select
                                                </button>
                                            </div>
                                        </li>
                                    );
                                })
                            ) : (
                                <p>No Contest Available</p>
                            )}
                        </ul>
                    </div>
                ) : eligibility ? (
                    contestData !== null &&
                    contestData !== undefined &&
                    !loading ? (
                        <div className={'contest-card'}>
                            <div className={'column-2-only-mobile'}>
                                <div className={'live-box'}>
                                    {showTimer &&
                                    contestSelected &&
                                    timeLeft !== null ? (
                                        <>
                                            <h4>Contest Ends In</h4>
                                            <Timer
                                                expiryTimestamp={timeLeft}
                                                show={[1, 1, 1, 1]}
                                                expiryHandler={expiryHandler}
                                            />
                                        </>
                                    ) : null}
                                </div>
                            </div>
                            <div className={'contest-details'}>
                                {/*<h3 className={'title'}>Contest</h3>*/}
                                <div className={'head'}>
                                    <h4>{contestData.name}</h4>
                                </div>

                                <div className={'timing'}>
                                    <ul>
                                        <li className={'duration'}>
                                            Duration - {contestData.duration}{' '}
                                            min
                                        </li>
                                        <li>
                                            Max Points -{' '}
                                            {contestData.totalScore}
                                        </li>
                                        <li className={'start'}>
                                            Begins :{' '}
                                            <span>
                                                {moment(
                                                    contestData.startTime
                                                ).format(
                                                    'hh:mm a - DD MMM'
                                                )}{' '}
                                            </span>
                                        </li>
                                        <li className={'end'}>
                                            Ends :{' '}
                                            <span>
                                                {' '}
                                                {moment(
                                                    contestData.endTime
                                                ).format(
                                                    'hh:mm a - DD MMM'
                                                )}{' '}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                {isContestLive ? (
                                    isAttempted ? (
                                        <p className={'already-attempted'}>
                                            Already Attempted
                                        </p>
                                    ) : (
                                        <div className={'start-buttons'}>
                                            {!(
                                                contestData.blackListed === true
                                            ) && (
                                                <button
                                                    className={'start-btn'}
                                                    onClick={() => {
                                                        navigate(
                                                            '/instructions'
                                                        );
                                                    }}
                                                >
                                                    Start Contest
                                                </button>
                                            )}
                                            {contestData.blackListed ===
                                                true && (
                                                <p className="blacklisted-text">
                                                    You are not eligible for
                                                    this contest as you have not
                                                    given any contest in the
                                                    module
                                                </p>
                                            )}
                                        </div>
                                    )
                                ) : (
                                    <Fragment>
                                        <div className={'start-buttons'}>
                                            {contestNotStarted ? (
                                                <button className={'start-btn'}>
                                                    Not Started Yet
                                                </button>
                                            ) : null}

                                            {!contestNotStarted && userRole !== "precourse-user" ? (
                                                <button
                                                    className={'start-btn'}
                                                    onClick={() => {
                                                        // navigate('/instructions');
                                                        window.open(
                                                            'https://course.acciojob.com/',
                                                            '_self'
                                                        );
                                                    }}
                                                >
                                                    Solve in Gym
                                                </button>
                                            ) : null}
                                        </div>

                                        {!contestNotStarted && userRole === 'precourse-user' ? (
                                            <p className="deadline-passed-text">
                                                Contest Deadline has passed
                                            </p>
                                        ) : null}
                                    </Fragment>
                                )}
                            </div>
                            {/* <button
                                onClick={deSelectContest}
                                className={'view-all-contest'}
                            >
                                View all contest
                            </button> */}
                        </div>
                    ) : (
                        <Loader />
                    )
                ) : (
                    <p>You are not eligible for this contest</p>
                )}
            </div>
            <div className={'column-2'}>
                <div className={'live-box'}>
                    {showTimer && contestSelected && timeLeft !== null ? (
                        <>
                            <h4>Contest Ends In</h4>
                            <Timer
                                expiryTimestamp={timeLeft}
                                show={[1, 1, 1, 1]}
                                expiryHandler={expiryHandler}
                            />
                        </>
                    ) : null}
                </div>
            </div>

            <div className={'column-3'}>
                <div className={'lottie-animation'}>
                    <Lottie
                        style={{ marginTop: '100px' }}
                        loop
                        animationData={codinglottie}
                        play
                        // style={{ width: 150, height: 150 }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
