import { FC, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';

type PropsType = {
    displayText1: string;
    displayText2: string;
    lottieIcon: any;
    timerSeconds: number;
};

const RedirectWithTimer: FC<PropsType> = ({displayText1, displayText2,lottieIcon, timerSeconds}) => {
    const [countdown, setCountdown] = useState(timerSeconds);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
            return () => clearInterval(timer);
        }, 1000);
    }, [countdown]);

    return (
        <div
            className={'contest'}
            id={'contest-root'}
            style={{
                height: 'fit-content',
            }}
        >
            <div className={''}></div>
            <div className="redirect-modal-container">
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <h3 className='heading-primary'>
                        {displayText1}
                    </h3>
                    <Lottie
                        loop
                        animationData={lottieIcon}
                        play
                        className={'animation'}
                    />
                    <h2 className='heading-secondary'>
                        {displayText2}
                    </h2>
                    {countdown > 0 ? (
                        <p className="redirect-url">
                            Redirecting in{' '}
                            <span>{countdown}</span>{' '}
                            seconds...
                        </p>
                    ) : (
                        <p className="redirect-url">Redirecting now...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RedirectWithTimer;
