import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
// import { CURRENT_QUESTION } from "./WebDevQuestionScreen"

const GithubAuthRedirect = () => {
    // const history = useHistory()
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        const questionId = localStorage.getItem('questionId');
        const urlParams = new URLSearchParams(location.search);
        console.log('QUESTION ID', questionId);
        // const questionParams = questionParamsFromLocalStore? JSON.parse(questionParamsFromLocalStore) : null
        //
        // if (questionId !== undefined && questionId !== '') {
        // 	navigate(`/web-idle?question=${questionId}&code=`);
        // }

        // const urlParams = new URLSearchParams(location.search);
        if (urlParams.get('code')) {
            // Assuming that the current question details are already stored in localStorage
            const questionId = localStorage.getItem('questionId');
            console.log('QUESTION ID', questionId);
            // const questionParams = questionParamsFromLocalStore? JSON.parse(questionParamsFromLocalStore) : null

            if (questionId !== undefined && questionId !== '') {
                navigate(
                    `contest/web-dev-question?question=${questionId}&code=${urlParams.get(
                        'code',
                    )}`,
                );
            }
        }
    }, []);

    return <></>;
};

export default GithubAuthRedirect;
