import { LocalStorageHelper } from '../LocalStorage';

export class TokenService {
	// static TOKEN_PARAM = 'token';

	constructor(public tokenKey: string) {}

	async getToken() {
		try {
			return await LocalStorageHelper.getItem(this.tokenKey);
		} catch (error) {
			return '';
		}
	}

	async setToken(token: string) {
		await LocalStorageHelper.setItem(this.tokenKey, token);
	}

	async clearToken() {
		await LocalStorageHelper.removeItem(this.tokenKey);
	}
}
