import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// toast.configure();

function notifySuccess(msg: string) {
	console.log('msg', msg);
	toast.success(msg, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 2000,
	});
}

const notifySuccessQk = (msg: string) => {
	toast.success(msg, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 2000,
	});
};

const notifyEmail = (msg: string) => {
	toast.success(msg, {
		position: toast.POSITION.TOP_RIGHT,
	});
};

const notifyError = (msg: string) => {
	toast.error(msg, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 2000,
	});
};

const notifyWarning = (msg: string) => {
	toast.warning(msg, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: false,
	});
};

const notifyWarningQk = (msg: string) => {
	toast.warning(msg, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: false,
	});
};

const notifyInfo = (msg: string) => {
	toast.info(msg, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 2000,
	});
};

export {
	notifyInfo,
	notifyWarning,
	notifyError,
	notifySuccess,
	notifySuccessQk,
	notifyWarningQk,
	notifyEmail,
};
