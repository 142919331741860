import { apiFailureErrorMessage, HttpService } from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class ContestService {
    constructor(private httpService: HttpService) {}

    async fetchAllContest() {
        const url = `${ENDPOINTS.ROOT}/contests/all`;
        // console.log('FETCH CONTEST', body);
        try {
            const { data } = await this.httpService.get<any>(url);
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async fetchContest(body: any) {
        const url = `${ENDPOINTS.ROOT}/contests/one`;
        console.log('FETCH CONTEST', body);
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: body,
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async fetchQuestions(body: any) {
        const url = `${ENDPOINTS.ROOT}/contests/questions/all`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: body,
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async fetchProctingInformation(body: any) {
        const url = `${ENDPOINTS.ROOT}/contests/proctor`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: body,
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async submitContest(body: any) {
        const url = `${ENDPOINTS.ROOT}/contests/submit`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: body,
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

    async recordRating(ratingData: any) {
		const url = `${ENDPOINTS.ROOT}/feedback/record-feedback`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { ...ratingData },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

    async getContestPopup(sectionId: string) {
        const url = `${ENDPOINTS.ROOT}/popup/getPopupBySection`;
        try {
            const { data } = await this.httpService.post<any>(url, {
                data: {
                    sectionType: "CONTEST",
                    sectionId,
                },
            });
            return data;
        } catch (error) {
            throw new Error(apiFailureErrorMessage);
        }
    }

}
