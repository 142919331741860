import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { services } from '../../api';

export const fetchUserReport = createAsyncThunk('users/getReport', async () => {
    try {
        return await services.userService.getReport();
    } catch (error) {
        console.log(error);
    }
});

const checkRepeatition = (data: any[]) => {
    console.log('This is the data ', data);
    data = data.filter(
        (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
    );

    console.log('From Set', data);
    return data;
};

export const fetchCourseList = createAsyncThunk(
    'users/enrollment',
    async () => {
        try {
            const res: any = {};
            let { data } = await services.courseService.getAllCourseData();
            data = checkRepeatition(data);
            const latestCourseID = data[0].id;
            const latestCourseName = data[0].title;
            localStorage.setItem('selected-course-token', latestCourseID);
            res.latestCourseID = latestCourseID;
            res.latestCourseName = latestCourseName;
            res.courseList = data;
            console.log('USER STATE RESPONSE -> ', res);
            return res;
        } catch (error) {
            console.log(error);
        }
    }
);

export const fetchAllTagsList = createAsyncThunk(
    'users/topic-tags',
    async () => {
        try {
            const res = await services.courseService.getAllTags();
            console.log('USER STATE RESPONSE -> ', res);
            return res;
        } catch (error) {
            console.log(error);
        }
    }
);

export const fetchLiveDoubtsSlots = createAsyncThunk(
    'users/live-doubt-slots',
    async () => {
        try {
            const res = await services.courseService.getLiveDoubtsSlots();
            console.log('USER STATE LIVE DOUBTS RESPONSE -> ', res);
            return res;
        } catch (error) {
            console.log(error);
        }
    }
);

interface UserState {
    user: any;
    userReportCard: any;
    courseList: any[];
    userDataStatus: boolean;
    userCourseStatus: boolean;
    latestCourseID: any;
    latestCourseName: any;
    interviewCleared: boolean;
    topicTags: any[];
    chartData: any[];
    streak: number | null;
    liveDoubtsSlots: unknown;
    username: string | null;
    userRole: string | null;
}

const initialState: UserState = {
    user: null,
    username: null,
    userReportCard: null,
    courseList: [],
    userDataStatus: false,
    userCourseStatus: false,
    latestCourseID: null,
    latestCourseName: null,
    interviewCleared: false,
    topicTags: [],
    chartData: [],
    streak: null,
    liveDoubtsSlots: [],
    userRole: null,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserName: (state, action: PayloadAction<any>) => {
            state.username = action.payload;
        },
        setUserData: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setUserReportCardData: (state, action: PayloadAction<any>) => {
            state.userReportCard = action.payload;
        },
        setUserFullCourseEnrollmentData: (state) => {
            console.log(state);
        },
        updateCourseList: (state, action: PayloadAction<any[]>) => {
            state.courseList = action.payload;
        },
        setUserRole: (state, action: PayloadAction<any>) => {
            state.userRole = action.payload;
        },
    },
    extraReducers: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchLiveDoubtsSlots.pending]: (
            state: UserState,
            action: PayloadAction<any[]>
        ) => {
            return;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchLiveDoubtsSlots.fulfilled]: (
            state: UserState,
            action: PayloadAction<any[]>
        ) => {
            state.liveDoubtsSlots = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchLiveDoubtsSlots.rejected]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            // state.authError = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchUserReport.pending]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            state.userDataStatus = false;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchUserReport.fulfilled]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            console.log('USER REPORT', action.payload);
            state.userDataStatus = true;
            state.userReportCard = action.payload.reportCard;
            state.user = action.payload.user;
            state.interviewCleared = action.payload.reportCard.interviewCleared;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchUserReport.rejected]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            state.userDataStatus = false;
            // state.authError = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchCourseList.pending]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            state.userCourseStatus = false;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchCourseList.fulfilled]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            console.log('Action', action.payload);
            state.courseList = action.payload.courseList;
            state.latestCourseID = action.payload.latestCourseID;
            state.latestCourseName = action.payload.latestCourseName;
            state.userCourseStatus = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchCourseList.rejected]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            state.userDataStatus = false;
            // state.authError = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchAllTagsList.pending]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            state.userCourseStatus = false;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchAllTagsList.fulfilled]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            console.log('Action', action.payload);
            state.topicTags = action.payload;
            state.userCourseStatus = true;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        [fetchAllTagsList.rejected]: (
            state: UserState,
            action: PayloadAction<any>
        ) => {
            state.userDataStatus = false;
            // state.authError = action.payload
        },
    },
});

//Exporting Actions
export const {
    setUserData,
    setUserReportCardData,
    setUserFullCourseEnrollmentData,
    setUserName,
    setUserRole,
} = userSlice.actions;

export default userSlice.reducer;
