import Cookies from 'js-cookie';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useEffect, useState } from 'react';

// Only External Exports Above
import { services } from '../api';
import { useReduxDispatch } from '../redux/store';
import {
    fetchUserReport,
    setUserName,
    setUserRole,
} from '../redux/user/userSlice';

const userAuthStatusCheck = async () => {
    try {
        const data = await services.userService.getUserAuthStatus();
        if (data) {
            console.log({ data });
            const { isDetailsFilled, isEmailSet, isPasswordSet, isUserExist } =
                data;

            const check =
                isDetailsFilled && isEmailSet && isPasswordSet && isUserExist;

            if (!check) {
                window.open(
                    `https://authentication.acciojob.com?redirect_uri=${encodeURI(
                        window.location.href
                    )}`,
                    '_self'
                );
            }
        }
    } catch (error) {
        console.error(error);
    }
};

type tokenInterface = JwtPayload & {
    email: string;
    roles: any[];
};

const useLoginHook = () => {
    const dispatch = useReduxDispatch();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const extractUserData = (token: string) => {
        const tokenData = jwt_decode<tokenInterface>(token);
        const role = tokenData.roles[0];
        console.log('username', tokenData);
        dispatch(setUserName('Learner'));
        dispatch(setUserRole(role));
        // if (role === 'precourse-user') {
        //     window.open('https://precourse.acciojob.com', '_self');
        // } else

        setIsAuthenticated(true);
    };

    const getCredentials = async () => {
        const url = window.location.origin;
        const domainArr = new URL(url).hostname.split('.');
        domainArr.shift();
        const domain = domainArr.join('.');

        const redirect_uri = window.location.href;

        if (process.env.REACT_APP_TOKEN) {
            const localToken = process.env.REACT_APP_TOKEN;
            await localStorage.setItem('acciojobs-token', localToken);
            extractUserData(localToken);
        } else if (domain === 'web.app') {
            const alreadyPresentedToken = Cookies.get('acciojobs-token');
            try {
                if (alreadyPresentedToken) {
                    extractUserData(alreadyPresentedToken);
                } else {
                    const tokenFromPrompt = prompt('Please enter your token');
                    if (tokenFromPrompt) {
                        await Cookies.set('acciojobs-token', tokenFromPrompt);
                        await localStorage.setItem(
                            'acciojobs-token',
                            tokenFromPrompt
                        );
                        extractUserData(tokenFromPrompt);
                    }
                }
            } catch (err) {
                console.log('TOKEN ERROR', err);
            }
        } else {
            const userTokenFromCookie = Cookies.get('acciojobs-token');
            if (userTokenFromCookie) {
                await localStorage.setItem(
                    'acciojobs-token',
                    userTokenFromCookie
                );
                await userAuthStatusCheck();
                extractUserData(userTokenFromCookie);
            } else
                window.open(
                    `https://authentication.acciojob.com/?redirect_uri=${encodeURI(
                        redirect_uri
                    )}`,
                    '_self'
                );
        }
    };

    useEffect(() => {
        getCredentials();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchUserReport());
        }
    }, [isAuthenticated]);

    return isAuthenticated;
};

export default useLoginHook;
