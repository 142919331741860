import {
    makeServices,
    HttpService,
    TokenService,
    CourseTokenService,
} from '../utils';
import { UserService } from './UserService';
import { CourseService } from './CourseService';
import { CodingQuestionService } from './CodingQuestionService';
import { ContestService } from './ContestService';
import { WebDevQuestionService } from './WebDevQuestionService';

const tokenService = new TokenService('acciojobs-token');
const courseTokenService = new CourseTokenService('selected-course-token');
const httpService = new HttpService(tokenService, courseTokenService);
const userService = new UserService(httpService);
const courseService = new CourseService(httpService);
const codingQuestionService = new CodingQuestionService(httpService);
const webDevQuestionService = new WebDevQuestionService(httpService);
const contestService = new ContestService(httpService);


export interface Services {
    tokenService: TokenService;
    httpService: HttpService;
    userService: UserService;
    courseService: CourseService;
    codingQuestionService: CodingQuestionService;
    contestService: ContestService;
    webDevQuestionService: WebDevQuestionService;
}

export const { ServicesProvider, useServices } = makeServices<Services>({
    tokenService,
    httpService,
    userService,
    courseService,
    codingQuestionService,
    contestService,
    webDevQuestionService,
});

export const services = {
    tokenService,
    httpService,
    userService,
    courseService,
    codingQuestionService,
    contestService,
    webDevQuestionService,

};
