import React, { useEffect, useState } from 'react';
import RenderMarkdown from './DisplayAsMarkDown';
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io';

interface Props {
    statement: string;
    options: string[];
    save: any;
    response: string[];
}

const Mcq = (props: Props) => {
    const [answer, setAnswer] = useState<string[]>([]);

    const saveAnswer = (option: string) => {
        const temp = [option];
        console.log('mcq temp', temp);
        setAnswer(temp);
        props.save(temp);
    };

    useEffect(() => {
        console.log('props response', props.response);
        setAnswer(props.response);
    }, [props.response]);

    console.log('answer array', answer);

    return (
        <div className={'mcq conceptual-question'}>
            <div className={'question'}>{RenderMarkdown(props.statement)}</div>
            <div className={'options'}>
                <ul>
                    {props.options.map((el, index) => {
                        return (
                            <li
                                key={index + el}
                                onClick={() => {
                                    saveAnswer(el);
                                }}
                            >
                                <span>
                                    {answer.includes(el) ? (
                                        <IoIosRadioButtonOn />
                                    ) : (
                                        <IoIosRadioButtonOff />
                                    )}
                                </span>
                                <div className={'mcq-option'}>
                                    {RenderMarkdown(el)}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Mcq;
