import { apiFailureErrorMessage, HttpService } from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class CourseService {
	async fetchStartUrl(meetingId: string) {
		const url = `${ENDPOINTS.ROOT}/video/getNewUrl/${meetingId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	constructor(private httpService: HttpService) {}

	async fetchPreCourse() {
		const url = `${ENDPOINTS.ROOT}/course/fetchPreCourse`;
		try {
			const { data } = await this.httpService.post<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getVideo(videoId: any) {
		const url = `${ENDPOINTS.ROOT}/video/${videoId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getLiveSession(type: string) {
		const url = `${ENDPOINTS.ROOT}/live-session/${type}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async completeOrientation(moduleId: string) {
		const url = `${ENDPOINTS.ROOT}/course/complete-pre-course-orientation`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					// need changes
					moduleId: moduleId,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingTestLink() {
		const url = `${ENDPOINTS.ROOT}/user-report-card/get-admission-coding-test`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingQuestionsForModule(moduleId: string) {
		const url = `${ENDPOINTS.ROOT}/user-course-enrollment/${moduleId}/codingQuestions`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingTestResult() {
		const url = `${ENDPOINTS.ROOT}/user-report-card/coding-test-status`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCourseData() {
		const url = `${ENDPOINTS.ROOT}/course/fetchCourse`;
		try {
			const { data } = await this.httpService.post<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getUpcomingMeetingIds(courseId: string) {
		const url = `${ENDPOINTS.ROOT}/video/getUpcomingZoomClasses/${courseId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getRecordings(moduleId: string) {
		const url = `${ENDPOINTS.ROOT}/video/showLectures/${moduleId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	// get all courses by userID

	async getAllCourseData() {
		const url = `${ENDPOINTS.ROOT}/user-course-enrollment/getunits`;
		try {
			const { data } = await this.httpService.get<any>(url);
			// console.log("All course data",data)
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	// async getAllCourseTitle(id:any) {
	//   const url = `${ENDPOINTS.ROOT}/course/coursename/${id}`
	//   try {
	//     const { data } = await this.httpService.get<any>(url)
	//     // const { data } = await this.httpService.get<any>(url,{data: {courseID:id}})
	//     // console.log("courseTitle",data)
	//     return data
	//   } catch (error) {
	//     throw new Error(apiFailureErrorMessage)
	//   }
	// }

	async getFullCourseQuestionsForModule(moduleId: string) {
		const url = `${ENDPOINTS.ROOT}/course/${moduleId}/questions`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingTestResultAfterFailure() {
		const url = `${ENDPOINTS.ROOT}/user-report-card/get-admission-coding-test-link-after-failure`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getFullCourseVideos(
		tags: string[],
		type: string[],
		startDate: any,
		endDate: any
	) {
		const url = `${ENDPOINTS.ROOT}/course/fetchCourseVideos`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: { tags, types: type, startDate, endDate },
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async markVideoViewed(body: any) {
		const url = `${ENDPOINTS.ROOT}/course/mark-video-viewed`;
		try {
			const { data } = await this.httpService.post<any>(
				url
				// { data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	// async getAllCodingQuestions(
	// 	page: number,
	// 	difficulty: any[],
	// 	tagFilters: any[],
	// 	name: string
	// ) {
	// 	const url = `${ENDPOINTS.ROOT}/coding-question/all/${page}`;
	// 	try {
	// 		const { data } = await this.httpService.post<any>(url, {
	// 			data: {
	// 				tagFilters,
	// 				difficulty,
	// 				name,
	// 			},
	// 		});
	// 		return data;
	// 	} catch (error) {
	// 		throw new Error(apiFailureErrorMessage);
	// 	}
	// }

	async getAllCodingQuestions(
		page: number,
		solved: number,
		tagFilters: any[],
		difficulty: any[],
		bookmarked: boolean,
		name: string,
		courseId: string,
		questionSource: string
	) {
		const url = `${ENDPOINTS.ROOT}/coding-question/all/${page}`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					solved,
					bookmarked,
					tagFilters,
					difficulty,
					name,
					courseId,
					questionSource,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllSolvedCodingQuestions(page: number, difficulty: string[]) {
		const url = `${ENDPOINTS.ROOT}/coding-question/all/solved/${page}`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					difficulty,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllUnSolvedCodingQuestions(page: number, difficulty: string[]) {
		const url = `${ENDPOINTS.ROOT}/coding-question/all/unsolvedQuestions/${page}`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					difficulty,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingQuestionsStatus() {
		const url = `${ENDPOINTS.ROOT}/coding-question/status`;
		try {
			const { data } = await this.httpService.post<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCourseLeaderboard() {
		const url = `${ENDPOINTS.ROOT}/course/leaderboard`;
		try {
			const { data } = await this.httpService.post<any>(url);
			// console.log("LeaderBoard", data)
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getUserTotalScore() {
		const url = `${ENDPOINTS.ROOT}/course/myscore`;
		try {
			const { data } = await this.httpService.post<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getActiveContests(courseDetails: any) {
		const url = `${ENDPOINTS.ROOT}/contests/active`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: courseDetails,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getContestById(courseDetails: any) {
		const url = `${ENDPOINTS.ROOT}/contests/get`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: courseDetails,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getContestLeaderBoard(contestDetails: any) {
		const url = `${ENDPOINTS.ROOT}/contests/leaderboard`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: contestDetails,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCourseAssignments() {
		const url = `${ENDPOINTS.ROOT}/course/fetchAssignmentModules`;
		try {
			const { data } = await this.httpService.post<any>(url);
			// console.log("ALL ASSIGN", data)
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCourseEnrollment() {
		const url = `${ENDPOINTS.ROOT}/course/fetchFullCourseEnrollment`;
		try {
			const { data } = await this.httpService.post<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllTags() {
		const url = `${ENDPOINTS.ROOT}/attachedtags`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getLiveDoubtsSlots() {
		const url = `${ENDPOINTS.ROOT}/course/unit/details`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllBookmark() {
		const url = `${ENDPOINTS.ROOT}/bookmark/getbookmarks`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log(data);

			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async addBookmark(input: any) {
		const url = `${ENDPOINTS.ROOT}/bookmark/addbookmark`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: input,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async removeBookmark(input: any) {
		const url = `${ENDPOINTS.ROOT}/bookmark/removebookmark`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: input,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchAllCourses() {
		const url = `${ENDPOINTS.ROOT}/course/fetch/all`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllCourseStudents(body: any) {
		const url = `${ENDPOINTS.ROOT}/course/fetch/students`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: { body },
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getLink(body: any) {
		const url = `${ENDPOINTS.ROOT}/video/checkRoom`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: body,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async addLiveDoubt(body: any) {
		const url = `${ENDPOINTS.ROOT}/livedoubt/adddoubt`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: body,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchDetails(userID: any, userType: string) {
		const url = `${ENDPOINTS.ROOT}/session/get/${userID}/${userType}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	// async fetchAvailabilityDate({ date, sessionId, slot }) {
	// 	const url = `${ENDPOINTS.ROOT}/session/bookSessionWithMentor`;
	// 	try {
	// 		const { data } = await this.httpService.post<any>(url, {
	// 			data: {
	// 				date: date,
	// 				sessionId: sessionId,
	// 				slot: slot,
	// 			},
	// 		});
	// 		return data;
	// 	} catch (error) {
	// 		throw new Error(apiFailureErrorMessage);
	// 	}
	// }

	async cancelSession(sessionId: string) {
		const url = `${ENDPOINTS.ROOT}/session/cancel`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					sessionId,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async rescheduleSession(date: string, sessionId: string) {
		const url = `${ENDPOINTS.ROOT}/session/cancel`;
		try {
			const { data } = await this.httpService.post<any>(url, {
				data: {
					date,
					sessionId,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
