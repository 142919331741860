import { Modal, ModalClose, Sheet } from '@mui/joy';
import { FC, useState } from 'react';
import { services } from '../../api';
import AccioButton from '../elements/button';
import { notifySuccess } from '../notification';
import logo from '../../assets/acciojob-logo.svg';

export type PopupDataType = {
    popUpHeaderText: string;
    popupType: string;
    showPopup: boolean;
    feedbackType: string;
    ratings: Array<Rating>;
    lowestRatingText: string;
    highestRatingText: string;
    lessThanFour: string;
    moreThanThree: string;
    ctas: Array<CTA>;
};

type Rating = {
    rating: number;
    nextPopupId: string;
};

type CTA = {
    ctaType: string;
    ctaId: string;
    ctaText: string;
    actions: Array<Action>;
};

type Action = {
    actionType: string;
    actionUrl: string;
};

type FeedbackPropsType = {
    feedbackData: PopupDataType;
    onCloseCallback: () => void;
    contestId: string
}

const ContestFeedbackModal: FC<FeedbackPropsType> = ({
    feedbackData,
    onCloseCallback,
    contestId,
}) => {
    const [showModal, setShowModal] = useState(true);

    const [selectedRatingVal, setSelectedRatingVal] = useState<number | null>(
        null
    );

    const [textAreaValue, setTextAreaValue] = useState('');

    const handleTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setTextAreaValue(e.target.value);
    };

    const handleRatingClick = async (rating: number | null) => {
        setSelectedRatingVal(rating);

        try {
            const res = await services.contestService.recordRating({
                feedbackType: feedbackData.feedbackType,
                page: '/contest',
                rating: rating,
                sectionId: contestId,
                hasClosedFeedbackPopup: false,
            });
            notifySuccess(res?.message);
        } catch (error) {
            console.error(error);
        }
    };

    const onClose = () => {
        setShowModal(false);
        onCloseCallback();
    };

    const handleSubmitAction = async () => {
        try {
            const res = await services.contestService.recordRating({
                feedbackType: feedbackData.feedbackType,
                page: '/contest',
                rating: selectedRatingVal,
                feedback: textAreaValue,
                sectionId: contestId,
                hasClosedFeedbackPopup: false,
            });
            notifySuccess(res?.message);
        } catch (error) {
            console.error(error);
        } finally {
            onClose();
        }
    };

    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={feedbackData?.showPopup && showModal}
            onClose={onClose}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    maxWidth: 500,
                    borderRadius: 'md',
                    p: 0,
                    boxShadow: 'lg',
                    border: 'none',
                    outline: '1px solid transparent',
                }}
            >
                <ModalClose
                    variant="plain"
                    sx={{ m: 1, right: '-40px', top: '-40px' }}
                />
                <div className="all-feedback-modal-rating-wrapper">
                    <p className="modal-title">
                        {feedbackData?.popUpHeaderText}
                    </p>
                    <div className="modal-rating-container">
                        <div className="rating-wrapper">
                            <div className="rating-holder">
                                {feedbackData?.ratings?.length > 0
                                    ? feedbackData?.ratings?.map(
                                          (rating: Rating, i: number) => (
                                              <div
                                                  className="rating-component-wrapper"
                                                  key={i}
                                              >
                                                  <span
                                                      className={`rating-component ${
                                                          rating?.rating <=
                                                          (selectedRatingVal ||
                                                              0)
                                                              ? 'selected'
                                                              : ''
                                                      }`}
                                                      onClick={() =>
                                                          handleRatingClick(
                                                              rating?.rating ||
                                                                  null
                                                          )
                                                      }
                                                  ></span>
                                                  <span className="rating-number">
                                                      {rating?.rating}
                                                  </span>
                                              </div>
                                          )
                                      )
                                    : null}
                            </div>
                            <div className="modal-rating-text">
                                <span className="text">
                                    {feedbackData?.lowestRatingText}
                                </span>
                                <span className="text">
                                    {feedbackData?.highestRatingText}
                                </span>
                            </div>
                        </div>

                        {selectedRatingVal ? (
                            <div className="modal-comment-wrapper">
                                <div className="modal-input-feedback-wrapper">
                                    <p className="modal-input-feedback-title">
                                        {selectedRatingVal > 3
                                            ? feedbackData?.moreThanThree
                                            : feedbackData?.lessThanFour}
                                    </p>
                                    <textarea
                                        cols={30}
                                        rows={5}
                                        className="modal-input-feedback"
                                        value={textAreaValue}
                                        onChange={handleTextAreaChange}
                                        placeholder="Describe here"
                                    />
                                </div>
                            </div>
                        ) : null}

                        <div className="modal-footer-wrapper">
                            <img
                                src={logo}
                                alt="acciojob-logo"
                            />
                            {feedbackData?.ctas?.length
                                ? feedbackData?.ctas.map((cta: CTA) => (
                                      <AccioButton
                                          title={cta?.ctaText}
                                          disabled={selectedRatingVal === null}
                                          customClass={
                                              selectedRatingVal === null
                                                  ? 'disabled-btn'
                                                  : ''
                                          }
                                          action={handleSubmitAction}
                                      />
                                  ))
                                : null}
                        </div>
                    </div>
                </div>
            </Sheet>
        </Modal>
    );
};

export default ContestFeedbackModal;
