import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { fetchQuestions } from '../redux/contest/contestSlice';
import { useReduxDispatch } from '../redux/store';

const QuestionNavigator = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useReduxDispatch();

    const contestState = useSelector((state: any) => state.contest);

    const {
        codingQuestions,
        contestStarted,
        contestEnded,
        contestId,
        totalAttempts,
        contestHash,
        webDevQuestions,
        showModal,
        submitCallMade,
        isAttempted,
        forcefullyStart,
        conceptualQuestions,
        sqlQuestions,
        loading,
    } = contestState;

    const [active, setActive] = useState<string>('');

    useEffect(() => {
        const questionId: Array<string | null> | null | string =
            queryString.parse(location.search).question;
        console.log('Active Question', questionId);
        if (typeof questionId === 'string') {
            setActive(questionId);
        }
    }, [location]);

    const fetchQuestionsOnTabChange = () => {
        if (!loading) {
            const data = {
                contestId: contestId,
                contestHash: contestHash,
            };
            dispatch(fetchQuestions(data));
        }
    };

    return (
        <div className={'question-navigator'}>
            <ul>
                <li
                    className={'all-btn'}
                    onClick={() => {
                        navigate(
                            `/contest/all-questions?contestID=${contestId}`
                        );
                    }}
                >
                    All
                </li>
                {conceptualQuestions.map((el: any, index: number) => (
                    <li
                        className={`${
                            active === el.questionId
                                ? 'active-question'
                                : el.isQuestionAttemptedByUser
                                ? 'attempted-question'
                                : ''
                        }`}
                        key={el.questionId}
                        onClick={() => {
                            fetchQuestionsOnTabChange();
                            navigate(
                                `/contest/solve/conceptual-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                            );
                        }}
                    >
                        {index + 1}
                    </li>
                ))}
                {codingQuestions.map((el: any, index: number) => (
                    <li
                        className={`${
                            active === el.questionId
                                ? 'active-question'
                                : el.isQuestionAttemptedByUser
                                ? 'attempted-question'
                                : ''
                        }`}
                        key={el.questionId}
                        onClick={() => {
                            fetchQuestionsOnTabChange();
                            navigate(
                                `/contest/solve/coding-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                            );
                        }}
                    >
                        {conceptualQuestions.length + index + 1}
                    </li>
                ))}
                {webDevQuestions.map((el: any, index: number) => (
                    <li
                        className={`${
                            active === el.questionId
                                ? 'active-question'
                                : el.isQuestionAttemptedByUser
                                ? 'attempted-question'
                                : ''
                        }`}
                        key={el.questionId}
                        onClick={() => {
                            fetchQuestionsOnTabChange();
                            navigate(
                                `/contest/solve/web-dev-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                            );
                        }}
                    >
                        {conceptualQuestions.length +
                            codingQuestions.length +
                            index +
                            1}
                    </li>
                ))}
                {sqlQuestions.map((el: any, index: number) => (
                    <li
                        className={`${
                            active === el.questionId
                                ? 'active-question'
                                : el.isQuestionAttemptedByUser
                                ? 'attempted-question'
                                : ''
                        }`}
                        key={el.questionId}
                        onClick={() => {
                            fetchQuestionsOnTabChange();
                            navigate(
                                `/contest/solve/sql-question?contestID=${contestId}&question=${el.questionId}&pool=${el.poolId}`
                            );
                        }}
                    >
                        {conceptualQuestions.length +
                            codingQuestions.length +
                            webDevQuestions.length +
                            index +
                            1}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default QuestionNavigator;
