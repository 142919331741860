import queryString from 'query-string';
import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Only External Exports Above
import './App.scss';
import AppRouters from './AppRouters';
import {
    fetchAllContests,
    fetchContests,
    handleWebCam,
    setContestId,
    setContestSelected,
} from './redux/contest/contestSlice';
import { useReduxDispatch } from './redux/store';
import './styles/style.scss';

import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import selection from './assets/lottie/selection.json';
import PopUpBox from './components/modals/PopupBox';
import RedirectWithTimer from './components/RedirectWithTimer';
import { OFFLINE_ET_CONTEST } from './constants';
import useLoginHook from './custom_hooks/useLoginHook';
import ErrorFallBack from './screens/ErrorFallBack';

function App() {
    const isAuthenticated = useLoginHook();
    const dispatch = useReduxDispatch();
    const location = useLocation();
    const { user } = useSelector((state: any) => state.user);
    const contestID: Array<string | null> | null | string = queryString.parse(
        location.search
    ).contestID;
    const isWebCamAllowed: Array<string | null> | null | string =
        queryString.parse(location.search).isWebCamAllowed;

    const ContestState = useSelector((state: any) => state.contest);
    const { redirectionLinkForTrackSelection } = ContestState;

    useEffect(() => {
        if (
            contestID !== undefined &&
            contestID !== null &&
            contestID.length === 36
        ) {
            dispatch(setContestId(contestID));
            if (typeof contestID === 'string') {
                dispatch(fetchContests(contestID));
            }
            dispatch(setContestSelected(true));
        } else {
            dispatch(fetchAllContests());
        }
    }, []);

    useEffect(() => {
        const isWebCamAllowedLocal = localStorage.getItem('isWebCamAllowed');
        if (isWebCamAllowed !== undefined && isWebCamAllowed !== null) {
            if (isWebCamAllowed === 'false') {
                dispatch(handleWebCam(false));
                localStorage.setItem(
                    'isWebCamAllowed',
                    String(isWebCamAllowed)
                );
            } else {
                dispatch(handleWebCam(true));
                localStorage.removeItem('isWebCamAllowed');
            }
        } else if (isWebCamAllowedLocal) {
            if (isWebCamAllowedLocal === 'false') {
                dispatch(handleWebCam(false));
            } else {
                dispatch(handleWebCam(true));
            }
        } else {
            dispatch(handleWebCam(true));
        }
    }, []);

    useEffect(() => {
        if (
            redirectionLinkForTrackSelection?.length > 0 &&
            user &&
            user.signupPageType !== OFFLINE_ET_CONTEST
        ) {
            setTimeout(() => {
                window.location.href = redirectionLinkForTrackSelection;
            }, 3000);
        }
    }, [user, redirectionLinkForTrackSelection]);

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallBack}
            onReset={() => {}}
        >
            {isAuthenticated ? (
                <Fragment>
                    <AppRouters />
                    {redirectionLinkForTrackSelection?.length &&
                    user &&
                    user.signupPageType !== OFFLINE_ET_CONTEST ? (
                        <PopUpBox
                            handleClose={() => {
                                window.location.href =
                                    redirectionLinkForTrackSelection;
                            }}
                            openPopUp={true}
                        >
                            <RedirectWithTimer
                                lottieIcon={selection}
                                timerSeconds={3}
                                displayText1="Selecting Track in our Precourse is mandatory for attempting any Contest."
                                displayText2="Redirecting you to Track Selection Page."
                            />
                        </PopUpBox>
                    ) : null}
                </Fragment>
            ) : null}
        </ErrorBoundary>
    );
}

export default App;
