import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';

import React, { ReactNode } from 'react';

type PopUpBoxProps = {
	children: ReactNode;
	handleClose: () => void;
	openPopUp: boolean;
};

const PopUpBox: React.FC<PopUpBoxProps> = ({
	children,
	handleClose,
	openPopUp,
}) => {
	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={openPopUp}
			onClose={handleClose}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
                margin: "10px",
                border: "none"
			}}
		>
			<Sheet
				variant="outlined"
				sx={{
					borderRadius: 'md',
					p: 2,
					boxShadow: 'lg',
                    height: 'fit-content',
                    border: "none"
				}}
			>
				<ModalClose
					variant="outlined"
					sx={{
						top: 'calc(-1/4 * var(--IconButton-size))',
						right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				{children}
			</Sheet>
		</Modal>
	);
};

export default PopUpBox;
